import { TagName } from "../types/Tag";

/**
 * @param {Array} tags Array of tag objects { name: String, include: Boolean }
 * @returns MongoDB query
 */
export default function makeQuery({
  included,
  excluded,
  ids,
}: {
  included: TagName[];
  excluded: TagName[];
  ids: string[];
}) {
  let filter: { $and?: any[]; tags?: any; $or?: any[]; _id?: any } = {};
  let hasTags = false;
  // Could probably get rid of hasTags but I'm not comfortable enough with the code yet
  if (included.length || excluded.length) hasTags = true;
  if (!hasTags) return filter;
  if (included.length && excluded.length)
    filter.$and = [{ tags: { $all: included } }, { tags: { $nin: excluded } }];
  else {
    if (included.length) filter.tags = { $all: included };
    if (excluded.length) filter.tags = { $nin: excluded };
  }
  if (ids.length) {
    if (hasTags) filter = { $or: [filter, { _id: { $in: ids } }] };
    else filter._id = { $in: ids };
  }
  return filter;
}
