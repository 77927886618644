import { Paper, Stack } from "@mui/material";
import {
  AccountCircleTwoTone,
  ExitToAppTwoTone,
  HomeTwoTone,
  SearchTwoTone,
} from "@mui/icons-material";

import { Fuse, Split } from "../AdministrationControls";
import LinkButton from "../TooltipButton";

import { useRecoilValue } from "recoil";
import { isAdminState, isLoggedInState } from "../../state";
import { selectedClipIdsState } from "../../state/clips";

function AdminControls() {
  const selectedClipIds = useRecoilValue(selectedClipIdsState);
  const isAdmin = useRecoilValue(isAdminState);
  const fuse = isAdmin && selectedClipIds?.length > 1;
  const split = isAdmin && selectedClipIds?.length == 1;
  return (
    <>
      {fuse && <Fuse />}
      {split && <Split />}
    </>
  );
}

export function Navbar() {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const Spacer = () => <div className="w-full flex-1" />;
  return (
    <Paper square elevation={1} id="navigation" className="relative">
      <Stack className="h-full w-full" flexWrap="nowrap">
        {/* <Link to="/">
          <Tooltip title="Home" placement="right">
            <div
              id="logo"
              className="flex aspect-square w-full items-center justify-center"
            >
              <Logo className="w-[80%]" />
            </div>
          </Tooltip>
        </Link> */}
        <div className="flex aspect-square w-full items-center justify-center">
          <LinkButton to="/" title="Home" placement="right">
            <HomeTwoTone />
          </LinkButton>
        </div>
        <Spacer />
        <LinkButton to="/browse" title="Browse" placement="right">
          <SearchTwoTone />
        </LinkButton>
        <AdminControls />
        <Spacer />
        {isLoggedIn ? (
          <>
            <LinkButton to="/profile" title="Profile" placement="right">
              <AccountCircleTwoTone />
            </LinkButton>
            <LinkButton to="/logout" title="Logout" placement="right">
              <ExitToAppTwoTone />
            </LinkButton>
          </>
        ) : (
          <LinkButton to="/login" title="Login" placement="right">
            <ExitToAppTwoTone />
          </LinkButton>
        )}
      </Stack>
    </Paper>
  );
}

export default Navbar;
