import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { globalSelectionSignal } from "../state";

export default function useOnSelectionSignal() {
  const signal = useRecoilValue(globalSelectionSignal);
  return function onSelectionSignal(effect: Function) {
    useEffect(() => effect(), [signal]);
  };
}

export function useSelectionSignal() {
  return useRecoilValue(globalSelectionSignal);
}

export function useResetSelection() {
  return useResetRecoilState(globalSelectionSignal);
}
