import { produce } from "immer";
import deepEqual from "deep-equal";
import {
  DefaultValue,
  atom,
  atomFamily,
  selector,
  selectorFamily,
} from "recoil";

import { ClipsResponse } from "../helpers/api";
import { clipsPerPageState, currentPageState } from "./index";
import { animeStateByName } from "./anime";
import { sortedSelectedTagNamesState } from "./tags";

import { Clip } from "../types/Clip";
import { TagName } from "../types/Tag";

import { Metadata } from "../helpers/api";
import clipName from "../helpers/clipName";
import makeQuery from "../helpers/makeQuery";

export const keepInView = atom<string[]>({
  key: "keepInView",
  default: [],
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set keepInView to `, newValue);
      });
    },
  ],
});

export const clipsQueryFilter = selector({
  key: "clipsQueryFilter",
  get: ({ get }) => {
    const [excluded, included] = get(sortedSelectedTagNamesState);
    return makeQuery({
      included,
      excluded,
      ids: get(keepInView),
    });
  },
});

export const clipsQueryOptions = selector({
  key: "clipsQueryOptions",
  get: ({ get }) => ({
    sort: { anime: 1, startFrame: 1 },
    lean: true,
    metadata: true,
    limit: get(clipsPerPageState),
    skip: get(currentPageState) * get(clipsPerPageState),
  }),
});

export const clipsResponseState = selector<ClipsResponse>({
  key: "clipsResponseState",
  get: ({ get }) => {
    return {
      results: get(clipIdsState).map((_id) => get(clipState(_id))),
      metadata: get(metadataState),
    };
  },
  set: ({ set }, newValue) => {
    console.debug(`setting clips`, newValue);
    if (newValue instanceof DefaultValue) {
      return;
    }
    const { results, metadata } = newValue;
    set(clipStateSetter, results);
    set(metadataState, metadata);
    set(clipIdsState, (old) =>
      produce(old, (draft) => {
        if (results.length != old.length)
          return results.map((clip) => clip._id);
        for (const [index, value] of results.entries())
          draft[index] = value._id;
      }),
    );
  },
});

export const clipTagsSetter = selector<{
  ids: string[];
  add?: TagName | TagName[];
  remove?: TagName | TagName[];
}>({
  key: "clipTagsSetter",
  get: () => {
    throw new Error(`This is a setter`);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) throw new Error(`Cannot reset`);
    let { add = [], remove = [] } = newValue;
    if (!Array.isArray(add)) add = [add];
    if (!Array.isArray(remove)) remove = [remove];

    const { ids } = newValue;
    for (const id of ids) {
      set(clipState(id), (old) =>
        produce(old, (draft) => {
          for (const tag of add) draft.tags.push(tag);
          for (const tag of remove)
            draft.tags = draft.tags.filter((t) => t != tag);
        }),
      );
    }

    set(metadataState, (old) =>
      produce(old, (draft) => {
        for (const tag of add)
          draft.tagCounts[tag] =
            draft.tagCounts[tag] == null ? 1 : draft.tagCounts[tag] + 1;
        for (const tag of remove)
          draft.tagCounts[tag] =
            draft.tagCounts[tag] == null ? 0 : draft.tagCounts[tag] - 1;
      }),
    );
  },
});

export const clipStateSetter = selector<Clip | Clip[]>({
  key: "clipStateSetter",
  get: () => {
    throw new Error(`This is a setter`);
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) throw new Error(`Cannot reset`);
    if (!Array.isArray(newValue)) newValue = [newValue];
    for (const clip of newValue) {
      if (deepEqual(clip, get(clipState(clip._id)))) continue;
      set(clipState(clip._id), clip);
    }
  },
});

export const clipIdsState = atom<string[]>({
  key: "clipIdsState",
  default: [],
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set clipIdsState to `, newValue);
      });
    },
  ],
});

export const metadataState = atom<Metadata>({
  key: "metadataState",
  default: { total: 0, tagCounts: {} },
});

export const clipState = atomFamily<Clip, string>({
  key: "clipState",
  default: {} as Clip,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set clipState to `, newValue);
      });
    },
  ],
});

export const clipNameState = selectorFamily({
  key: "clipName",
  get:
    (clipId: string | undefined) =>
    ({ get }) => {
      if (!clipId) return "";
      const clip = get(clipState(clipId));
      const anime = get(animeStateByName(clip?.anime || ""));
      return clipName(clip, anime);
    },
});

export const selectedClipIdsState = atom<string[]>({
  key: "selectedClipIds",
  default: [],
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set selectedClipIdsState to `, newValue);
      });
    },
  ],
});

export const clipIsSelectedState = selectorFamily({
  key: "clipIsSelected",
  get:
    (clipId: string | undefined) =>
    ({ get }) => {
      if (!clipId) return false;
      const selectedClipIds = get(selectedClipIdsState);
      return selectedClipIds.includes(clipId);
    },
});

export const selectionExistsState = selector({
  key: "selectionExists",
  get: ({ get }) => !!get(selectedClipIdsState).length,
});
