import { DefaultValue, atom, selector } from "recoil";
import { setURLTags } from "../helpers/searchParams";
import { currentPageState, statsState } from "./index";
import { clipState, selectedClipIdsState } from "./clips";
import { isApiError } from "../helpers/api";
import { Tag, TagName } from "../types/Tag";

export const highlightedTagsState = atom<TagName[]>({
  key: "highlightedTags",
  default: [],
});

export const highlightExistsState = selector({
  key: "highlightExists",
  get: ({ get }) => !!get(highlightedTagsState).length,
});

export const allTagsMapState = selector<Map<TagName, Tag & { count: number }>>({
  key: "tagMap",
  get: ({ get }) => {
    const stats = get(statsState);
    if (isApiError(stats)) return new Map();
    return new Map(Object.entries(stats.tags));
  },
});

const allTagNamesState = selector({
  key: "allTagNames",
  get: ({ get }) => {
    const tags = get(allTagsMapState);
    return [...tags.keys()].sort((t1, t2) => t1.localeCompare(t2));
  },
});

const __selectedTags = atom<Map<TagName, boolean>>({
  key: "__selectedTags",
  default: new Map(),
});

export const selectedTagsState = selector({
  key: "selectedTags",
  get: ({ get }) => get(__selectedTags),
  set: ({ set, reset }, newValue) => {
    set(__selectedTags, newValue);
    if (newValue instanceof DefaultValue) newValue = new Map();
    setURLTags(newValue);
    reset(currentPageState);
    reset(highlightedTagsState);
  },
});

export const sortedSelectedTagNamesState = selector({
  key: "sortedSelectedTags",
  get: ({ get }) => {
    const sorted: [TagName[], TagName[]] = [[], []];
    const selectedTags = get(selectedTagsState);
    selectedTags.forEach((include, name) => sorted[Number(include)].push(name));
    return sorted;
  },
});

const selectedClipsState = selector({
  key: "selectedClipsState",
  get: ({ get }) => get(selectedClipIdsState).map((id) => get(clipState(id))),
});

export const removableTagNamesState = selector<Set<TagName>>({
  key: "removableTags",
  get: ({ get }) => {
    const clips = get(selectedClipsState);
    const all: Set<TagName> = new Set();
    for (const clip of clips) {
      for (const tag of clip?.tags || []) {
        all.add(tag);
      }
    }
    return all;
  },
});

const sharedTagNamesInSelectedState = selector<Set<TagName>>({
  key: "sharedtags",
  get: ({ get }) => {
    const clips = get(selectedClipsState);
    const counts: Record<TagName, number> = {};
    for (const clip of clips) {
      for (const tag of clip?.tags || []) {
        counts[tag] = counts[tag] == null ? 1 : counts[tag] + 1;
      }
    }
    const shared: Set<TagName> = new Set();
    for (const [tagName, count] of Object.entries(counts)) {
      if (count == clips.length) shared.add(tagName);
    }
    return shared;
  },
});

export const addableTagNamesState = selector<TagName[]>({
  key: "availableAddableTags",
  get: ({ get }) => {
    const allTagNames = get(allTagNamesState);
    const sharedTagNames = get(sharedTagNamesInSelectedState);
    const [_, includedTagNames] = get(sortedSelectedTagNamesState);

    return allTagNames.filter(
      (tag) => !sharedTagNames.has(tag) && !includedTagNames.includes(tag),
    );
  },
});

export const availableQueryTagNamesState = selector<TagName[]>({
  key: "availableQueryTags",
  get: ({ get }) => {
    const allTagNames = get(allTagNamesState);
    const selectedTags = get(selectedTagsState);
    return allTagNames.filter((tagName) => !selectedTags.has(tagName));
  },
});
