import { useEffect, useState } from "react";

import {
  clipStateSetter,
  clipTagsSetter,
  clipsQueryFilter,
  metadataState,
  selectedClipIdsState,
} from "../state/clips";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { selectedTagsState } from "../state/tags";
import { globalQuerySignal, toastMessageState, userState } from "../state";
import { produce } from "immer";

import {
  ApiError,
  Method,
  isApiError,
  patchTags,
  patchUser,
  request,
} from "../helpers/api";
import { ClipUrlProps, apiUrl } from "../config";
import { TagName } from "../types/Tag";
import { User } from "../types/User";
import { useResetSelection } from "./useOnSelectionSignal";

export function useToastMessage() {
  const setToast = useSetRecoilState(toastMessageState);
  return function setToastMessage(error: string | ApiError) {
    if (typeof error == "string") return setToast(error);
    if (isApiError(error)) setToast(error.message);
  };
}

export function useRandomClips(
  howMany: number,
): [ClipUrlProps[], (howMany: number) => void] {
  const [clips, setClips] = useState<ClipUrlProps[]>([]);
  const setToast = useToastMessage();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    request(apiUrl("random", howMany.toString()), {
      method: Method.GET,
      signal,
    }).then((c) => (isApiError(c) ? setToast(c.message) : setClips(c || [])));
    return () => controller.abort();
  }, []);

  function more(howMany: number) {
    request(apiUrl("random", howMany.toString()), {
      method: Method.GET,
    }).then((c) =>
      isApiError(c) ? setToast(c.message) : setClips((old) => [...old, ...c]),
    );
  }

  return [clips, more];
}

export function useAddTag() {
  const updateTags = useSetRecoilState(clipTagsSetter);
  const setClips = useSetRecoilState(clipStateSetter);
  const setMetadata = useSetRecoilState(metadataState);
  const setToast = useToastMessage();
  const selectedClipIds = useRecoilValue(selectedClipIdsState);
  const filter = useRecoilValue(clipsQueryFilter);

  return function addTag(tag: TagName) {
    updateTags({ ids: selectedClipIds, add: tag });
    patchTags(
      { _id: selectedClipIds },
      { add: [tag] },
      { metadata: filter },
    ).then((response) => {
      if (isApiError(response)) return setToast(response.message);
      setClips(response.results);
      setMetadata(response.metadata);
    });
  };
}

export function useRemoveTag() {
  const updateTags = useSetRecoilState(clipTagsSetter);
  const setClips = useSetRecoilState(clipStateSetter);
  const setMetadata = useSetRecoilState(metadataState);
  const setToast = useToastMessage();
  const query = useResetRecoilState(globalQuerySignal);
  const sendSelection = useResetSelection();
  const selectedClipIds = useRecoilValue(selectedClipIdsState);
  const filter = useRecoilValue(clipsQueryFilter);
  const selectedTags = useRecoilValue(selectedTagsState);

  return function removeTag(tag: TagName) {
    if (selectedTags.has(tag)) sendSelection();
    updateTags({ ids: selectedClipIds, remove: tag });
    patchTags(
      { _id: selectedClipIds },
      { remove: [tag] },
      { metadata: filter },
    ).then((response) => {
      if (isApiError(response)) return setToast(response.message);
      setClips(response.results);
      setMetadata(response.metadata);
      if (selectedTags.has(tag)) query();
    });
  };
}

export function useUpdateUser() {
  const setUser = useSetRecoilState(userState);
  const setToast = useToastMessage();
  return function updateUser(update: Partial<User>) {
    patchUser(update).then((res) => {
      if (isApiError(res)) return setToast(res.message);
      console.log("setting user to", res);
      setUser(res);
    });
  };
}

export function useIncludeTag() {
  const setSelectedTags = useSetRecoilState(selectedTagsState);
  const query = useResetRecoilState(globalQuerySignal);
  return function includeTag(tag: TagName) {
    setSelectedTags((old) =>
      produce(old, (draft) => {
        draft.set(tag, true);
      }),
    );
    query();
  };
}

export function useExcludeTag() {
  const setSelectedTags = useSetRecoilState(selectedTagsState);
  const query = useResetRecoilState(globalQuerySignal);
  return function excludeTag(tag: TagName) {
    setSelectedTags((old) =>
      produce(old, (draft) => {
        draft.set(tag, false);
      }),
    );
    query();
  };
}

export function useSetQueryTag() {
  const setSelectedTags = useSetRecoilState(selectedTagsState);
  const query = useResetRecoilState(globalQuerySignal);
  return function setQueryTag(tag: TagName, include: boolean) {
    setSelectedTags((old) =>
      produce(old, (draft) => {
        draft.set(tag, include);
      }),
    );
    query();
  };
}

export function useRemoveQueryTag() {
  const setSelectedTags = useSetRecoilState(selectedTagsState);
  const query = useResetRecoilState(globalQuerySignal);
  return function removeQueryTag(tag: TagName) {
    setSelectedTags((old) =>
      produce(old, (draft) => {
        draft.delete(tag);
      }),
    );
    query();
  };
}
