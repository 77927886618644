import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { globalQuerySignal } from "../state";

export default function useOnQuerySignal() {
  const signal = useRecoilValue(globalQuerySignal);
  return function onSelectionSignal(effect: Function) {
    useEffect(() => effect(), [signal]);
  };
}

export function useQuerySignal() {
  return useRecoilValue(globalQuerySignal);
}

export function useResetQuery() {
  return useResetRecoilState(globalQuerySignal);
}
