import { useState } from "react";

// Components
import MergeIcon from "@mui/icons-material/Merge";
import { ContentCut } from "@mui/icons-material";

// State
import { useRecoilValue, useSetRecoilState } from "recoil";
import { keepInView, selectedClipIdsState } from "../state/clips";

// Networking
import { adminUrl } from "../config";
import { Credentials, Method, isApiError, request } from "../helpers/api";
import LinkButton, { TooltipButton } from "./TooltipButton";
import { useResetQuery } from "../hooks/useQuerySignal";
import { useResetSelection } from "../hooks/useOnSelectionSignal";
import { useToastMessage } from "../hooks/useAPI";

export function Fuse() {
  const selectedClipIds = useRecoilValue(selectedClipIdsState);
  const sendQuerySignal = useResetQuery();
  const sendSelectionSignal = useResetSelection();
  const setKeepInView = useSetRecoilState(keepInView);
  const setToast = useToastMessage();
  const [rendering, setRendering] = useState(false);

  const onMergeClick = () => {
    if (rendering) return;
    setRendering(true);
    request(adminUrl("fuse"), {
      method: Method.POST,
      json: { clips: selectedClipIds },
      credentials: Credentials.INCLUDE,
    })
      .then((response) => {
        if (isApiError(response)) return setToast(response);
        if ("newClip" in response) {
          setKeepInView((old) => [
            ...old.filter((id) => !selectedClipIds.includes(id)),
            response.newClip._id,
          ]);
        }
        sendSelectionSignal();
        sendQuerySignal();
      })
      .finally(() => setRendering(false));
  };

  return (
    <TooltipButton
      title="Merge"
      className=""
      placement="right"
      buttonProps={{ disabled: rendering, onClick: onMergeClick }}
    >
      <MergeIcon />
    </TooltipButton>
  );
}

export function Split() {
  const selectedClipIds = useRecoilValue(selectedClipIdsState);
  return (
    <LinkButton
      to={`/split/${selectedClipIds.at(0)}`}
      title="Cut"
      placement="right"
    >
      <ContentCut />
    </LinkButton>
  );
}
