import React, { useState } from "react";

import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export function TooltipButton(props: {
  children: React.ReactNode;
  className?: string;
  title: string;
  placement: TooltipProps["placement"];
  buttonProps?: IconButtonProps;
}) {
  const { children, className, buttonProps, title, placement } = props;
  type Color = IconButtonProps["color"];
  const defaultColor: Color = buttonProps?.["color"] || "primary";
  const [color, setColor] = useState<Color>();
  return (
    <div className={className}>
      <Tooltip
        title={title}
        placement={placement}
        onMouseEnter={() => setColor(defaultColor)}
        onMouseLeave={() => setColor(undefined)}
      >
        <ButtonContainer>
          <IconButton {...buttonProps} color={color}>
            {children}
          </IconButton>
        </ButtonContainer>
      </Tooltip>
    </div>
  );
}

export default function LinkButton(
  props: {
    to: LinkProps["to"];
    title: string;
    className?: string;
  } & Parameters<typeof TooltipButton>[0],
) {
  const { to, className, title, ...passthrough } = props;
  return (
    <Link to={to} className={className}>
      <TooltipButton title={title} {...passthrough} />
    </Link>
  );
}
