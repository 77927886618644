import { adminUrl, apiUrl, videoUrl } from "../config";
import { Anime } from "../types/Anime";
import { Clip } from "../types/Clip";
import { Tag, TagName } from "../types/Tag";

import ky from "ky";
import { User } from "../types/User";

export interface Stats {
  motd: string;
  FFMPEG: string;
  status: number;
  uptime: number;
  anime: Anime[];
  tags: Record<TagName, Tag & { count: number }>;
  total: number;
}

export type AnimeWithStats = Anime & {
  clipCount: number;
  commonTags: { _id: TagName; count: number }[];
};

export enum Method {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
}

export enum Credentials {
  OMIT = "omit",
  INCLUDE = "include",
}

export type ApiError = { error: true; message: string };
export function isApiError(res: any): res is ApiError {
  return !!res?.error;
}

export type Metadata = { tagCounts: Record<TagName, number>; total: number };
export type Response<T> = { results: T[] };
export type ClipsResponse = Response<Clip> & { metadata: Metadata };

export type ApiResponse<T> = T extends Clip
  ? ClipsResponse | ApiError
  : Response<T> | ApiError;

export enum SortDirection {
  ASCENDING = -1,
  DESCENDING = 1,
}

export enum ProjectionOption {
  HIDE = -1,
  SHOW = 1,
}

export type QueryOptions = {
  skip?: number;
  limit?: number;
  sort?: Record<string, SortDirection>;
  projection?: Record<string, ProjectionOption>;
};

export type RequestOptions = {
  method: Method;
  json?: any;
  skipError?: boolean;
  blob?: boolean;
  credentials?: Credentials;
  signal?: AbortSignal;
};

export async function request(url: string, options: RequestOptions) {
  const opts: RequestOptions = {
    credentials: Credentials.OMIT,
    ...options,
  };

  const stamp = `${Date.now()}`.slice(-4);
  console.info(`API: ${url} (${stamp})`, opts);

  const { skipError = false, blob = false } = opts;

  try {
    const res = await ky(url, opts);
    if (blob) return res.blob();

    const json: any = await res.json();
    if (skipError) return json;
    if (json?.error) throw json?.message || `Error without message`;
    return json;
  } catch (error: any) {
    if (error.name == "AbortError")
      return console.info(`API: ${url} (${stamp}) aborted`);
    console.error(error);
    if (!skipError) alert(error);
    return null;
  }
}

export function getAnimeStats(): Promise<AnimeWithStats[]> {
  return request(apiUrl("stats", "anime"), {
    method: Method.GET,
  });
}

export function getClips(
  query = {},
  options?: QueryOptions,
  requestOptions?: Omit<RequestOptions, "method">,
): Promise<ApiResponse<Clip>> {
  return request(apiUrl("clip"), {
    json: { query, options },
    ...requestOptions,
    method: Method.POST,
  });
}

export function getAnime(
  query = {},
  options: QueryOptions,
): Promise<ApiResponse<Anime>> {
  return request(apiUrl("anime"), {
    json: { query, options },
    method: Method.POST,
  });
}

export function patchTags(
  query: Record<string, any> | boolean = false,
  { add, remove }: { add?: TagName[]; remove?: TagName[] },
  options: QueryOptions & { metadata: Record<string, any> },
): Promise<ApiResponse<Clip>> {
  return request(apiUrl("clip"), {
    json: {
      query,
      add,
      remove,
      options: {
        lean: true,
        ...options,
      },
    },
    credentials: Credentials.INCLUDE,
    method: Method.PATCH,
  });
}

export function login(data: any) {
  return request(apiUrl("login"), {
    json: data,
    credentials: Credentials.INCLUDE,
    method: Method.POST,
  });
}

export function logout() {
  return request(apiUrl("logout"), {
    method: Method.POST,
    credentials: Credentials.INCLUDE,
  });
}

export async function getUser(): Promise<User | ApiError> {
  return await request(apiUrl("user"), {
    method: Method.POST,
    credentials: Credentials.INCLUDE,
    skipError: true,
  });
}

export async function getStats(): Promise<Stats | ApiError> {
  return await request(apiUrl(""), { method: Method.GET });
}

export async function patchUser(info: any) {
  return await request(apiUrl("user"), {
    json: info,
    credentials: Credentials.INCLUDE,
    method: Method.PATCH,
  });
}

export async function getFrame(_id: string, frame: number) {
  const blob = await request(adminUrl(`frame/${_id}`), {
    json: { frame: frame },
    method: Method.POST,
    credentials: Credentials.INCLUDE,
    blob: true,
  });
  const url = window.URL.createObjectURL(blob);
  return url;
}

export async function getClipBlob(clip: Clip): Promise<Blob> {
  const url = videoUrl(clip);
  if (!url) throw Error(`Incomplete clip data`);
  return request(url, {
    method: Method.GET,
    blob: true,
    credentials: Credentials.INCLUDE,
  });
}

export async function getNextClip(clip: Clip): Promise<Clip> {
  let { startFrame, anime } = clip;
  const { results } = await request(apiUrl("clip"), {
    json: {
      query: { startFrame: { $gt: startFrame }, anime },
      options: { sort: { startFrame: 1 }, limit: 1 },
    },
    method: Method.POST,
  });
  return results[0];
}

export async function getPreviousClip(clip: Clip): Promise<Clip> {
  let { endFrame, anime } = clip;
  const { results } = await request(apiUrl("clip"), {
    json: {
      query: { endFrame: { $lt: endFrame }, anime },
      options: { sort: { startFrame: -1 }, limit: 1 },
    },
    method: Method.POST,
  });
  return results[0];
}
