import { TagName } from "../types/Tag";

export function setSearchParams(params: Record<string, string>) {
  const url = new URL(window.location.toString());
  if (!params)
    return window.history.pushState(
      {},
      "",
      new URL(window.location.href.split("?")[0]),
    );

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }
  window.history.pushState({}, "", url);
}

export function unsetSearchParam(param: string) {
  const url = new URL(window.location.toString());
  url.searchParams.delete(param);
  window.history.pushState({}, "", url);
}

export function setURLTags(tags: Map<TagName, boolean>) {
  const existing = getSearchParam("tags");
  if (!tags.size && existing) return unsetSearchParam("tags");
  if (!tags.size) return;

  const encoded = [...tags.entries()]
    .map(([name, include]) => (include ? `${name}` : `-${name}`))
    .join(",");
  if (encoded != existing)
    setSearchParams({
      tags: encoded,
    });
}

export function getSearchParam(param: string) {
  const params = new URL(window.location.toString()).searchParams;
  return params.get(param);
}

export function getURLTags() {
  const tags = getSearchParam("tags");
  if (!tags) return false;
  const map: Map<string, boolean> = new Map();
  for (let name of tags.split(",")) {
    if (name.at(0) == "-") map.set(name.substring(1), false);
    else map.set(name, true);
  }
  return map;
}

export function setURLPage(page: number) {
  const existing = Number(getSearchParam("page"));
  if ((!page || page == 1) && existing) return unsetSearchParam("page");
  if (!page || page == 1) return;
  if (page != existing) return setSearchParams({ page: page.toString() });
}

export function getURLPage() {
  return Number(getSearchParam("page"));
}
