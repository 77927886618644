import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";

import { ThemeProvider, createTheme } from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import { enableMapSet } from "immer";
enableMapSet();

// Assets
import "@fontsource/roboto";
import "@fontsource/roboto-mono";
import "./index.css";

// Entry component
import App from "./App";

// Declare custom variants
declare module "@mui/material/styles" {
  interface TypographyVariants {
    display: React.CSSProperties;
    tag: React.CSSProperties;
    bigTag: React.CSSProperties;
    subtitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
    tag?: React.CSSProperties;
    bigTag?: React.CSSProperties;
    subtitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display: true;
    tag: true;
    bigTag: true;
    subtitle: true;
  }
}

declare module "@mui/material/styles" {
  interface TypeBackground {
    card: string;
  }
}

// Constants
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#398afe",
      dark: "#2861b2",
    },
    secondary: {
      main: "#2861b2",
    },
    success: {
      main: "#adff0a",
    },
    info: {
      main: "#39fee7",
    },
    error: {
      main: "#ff0a34",
    },
    background: {
      default: "#1c1c1c",
      card: "#2861b2",
    },
  },
  zIndex: {
    tooltip: 9001,
  },
  typography: {
    button: {
      fontFamily: "Barlow",
      fontWeight: 600,
    },
    display: {
      fontFamily: "Barlow",
      fontWeight: 600,
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    subtitle: {
      fontWeight: 500,
    },
    tag: {},
    bigTag: {
      fontSize: "1rem",
      paddingBottom: "0.1rem",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          tag: "h6",
          bigTag: "h6",
          display: "h1",
        },
      },
    },
  },
});

const container = document.getElementById("app");
if (container == null) throw new Error(`Container not found`);
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <App />
        </StyledThemeProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
