import { atom, selector, selectorFamily } from "recoil";
import { statsState } from ".";
import { getAnimeStats, isApiError } from "../helpers/api";

export const allAnimeState = selector({
  key: "allAnimeState",
  get: ({ get }) => {
    return get(statsState).anime;
  },
});

export const animeState = selectorFamily({
  key: "animeState",
  get:
    (id: string) =>
    ({ get }) => {
      const anime = get(allAnimeState);
      return anime?.find((anime) => anime._id == id);
    },
});

export const animeStateByName = selectorFamily({
  key: "animeStateByName",
  get:
    (name: string) =>
    ({ get }) => {
      const anime = get(allAnimeState);
      return anime?.find((anime) => anime.name == name);
    },
});

export const animeStatsState = atom({
  key: "animeStatsState",
  default: selector({
    key: "animeStatsState/query",
    get: async () => {
      const response = await getAnimeStats();
      if (isApiError(response)) throw Error("API is down");
      return response;
    },
  }),
});
