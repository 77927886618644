import { Suspense, useEffect } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { redirect } from "react-router-dom";

// Layout components
import Navbar from "./components/layout/Navbar";

// State
import { currentPageState, isAdminState, isLoggedInState } from "./state";
import { selectedTagsState } from "./state/tags";

import { Paper, Stack } from "@mui/material";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { getURLPage, getURLTags } from "./helpers/searchParams";

// Components
import Loader from "./components/Loader";
import ErrorBoundary from "./components/ErrorBoundary";
import Toast from "./components/Toast";
import { apiUrl } from "./config";
import { ThumbnailGriddyPage } from "./components/layout/ThumbnailGriddyPage";
import { useResetSelection } from "./hooks/useOnSelectionSignal";

export function App() {
  const isAdmin = useRecoilValue(isAdminState);
  const isLoggedIn = useRecoilValue(isLoggedInState);

  const resetBrowse = useResetRecoilState(selectedTagsState);
  const sendSelectionSignal = useResetSelection();

  // Set state from URL on page load, if present
  const setSelectedTags = useSetRecoilState(selectedTagsState);
  const setPage = useSetRecoilState(currentPageState);
  useEffect(() => {
    const { pathname } = new URL(window.location.toString());
    if (pathname != "/browse") return;

    const tags = getURLTags();
    if (tags) setSelectedTags(tags);

    const page = getURLPage();
    if (page && page != 1) setPage(page - 1);
  }, []);

  const router = createBrowserRouter([
    {
      element: (
        <Paper square id="page" elevation={1}>
          <Stack direction="row" gap={0} className="h-full w-full">
            <Navbar />
            <Suspense>
              <Outlet />
            </Suspense>
            <Toast />
          </Stack>
        </Paper>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: (
            <ThumbnailGriddyPage>
              <Outlet />
            </ThumbnailGriddyPage>
          ),
          path: "/",
          children: [
            {
              path: "/",
              lazy: () => import("./pages/Home"),
              errorElement: <ErrorBoundary />,
              loader: () => {
                resetBrowse();
                return null;
              },
            },
            {
              path: "/login",
              lazy: () => import("./pages/User/Login"),
              errorElement: <ErrorBoundary />,
              loader: () => isLoggedIn && redirect("/"),
            },
            {
              path: "/profile",
              lazy: () => import("./pages/User/Profile"),
              errorElement: <ErrorBoundary />,
              loader: () => !isLoggedIn && redirect("/login"),
            },
          ],
        },
        {
          path: "/browse",
          lazy: () => import("./pages/Browse"),
          errorElement: <ErrorBoundary />,
          loader: () => {
            sendSelectionSignal();
            return null;
          },
        },
        {
          path: "/clip/:_id",
          lazy: () => import("./pages/Clip"),
          errorElement: <ErrorBoundary />,
          loader: () => !isLoggedIn && redirect("/login"),
        },
        {
          path: "/split/:_id",
          lazy: () => import("./pages/Split"),
          errorElement: <ErrorBoundary />,
          loader: () => !isAdmin && redirect("/login"),
        },
        {
          path: "/anime",
          lazy: () => import("./pages/Anime"),
          errorElement: <ErrorBoundary />,
          loader: () => {
            resetBrowse();
            return null;
          },
        },
        {
          path: "/logout",
          loader: async () => {
            if (!isLoggedIn) return redirect("/");
            return redirect(`${apiUrl("logout")}`);
          },
          errorElement: <ErrorBoundary />,
          element: <Loader />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} key={`${isLoggedIn}`} />;
}

export default App;
