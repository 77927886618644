import { forwardRef } from "react";
import classNames from "classnames";
import styled from "styled-components";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const gridSize = 20;
export const Griddy = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${gridSize}rem, 1fr));
  align-content: start;
`;

export const Cell = forwardRef(function (
  {
    rowSpan = 1,
    columnSpan = 1,
    rowStart,
    columnStart,
    ...props
  }: {
    rowSpan?: number;
    columnSpan?: number;
    rowStart?: number;
    columnStart?: number;
  } & React.HTMLAttributes<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className="cell"
      style={{
        width: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        display: "block",
        aspectRatio: `${columnSpan} / ${rowSpan}`,
        gridColumnEnd: columnSpan != 1 ? `span ${columnSpan}` : "",
        gridRowEnd: rowSpan != 1 ? `span ${rowSpan}` : "",
        gridColumnStart: columnStart ? columnStart : "",
        gridRowStart: rowStart ? rowStart : "",
      }}
      {...props}
    />
  );
});

export function Space({
  rowSpan = 1,
  columnSpan = 1,
  className,
  rowStart,
  columnStart,
  debug,
}: {
  rowSpan?: number;
  columnSpan?: number;
  className?: string;
  rowStart?: number;
  columnStart?: number;
  debug?: boolean;
}) {
  return (
    <Cell
      rowSpan={rowSpan}
      columnSpan={columnSpan}
      rowStart={rowStart}
      columnStart={columnStart}
      className={classNames(className, debug && "bg-red-900")}
    ></Cell>
  );
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
}) => (condition ? wrapper(children) : children);

export const Item = forwardRef(function BaseItem(
  {
    children,
    label,
    className,
    rowSpan = 1,
    columnSpan = 1,
    id,
    padding = 3,
    gap = 2,
    noCap = false,
    elevation,
    rowStart,
    columnStart,
    paper = true,
  }: {
    label?: string;
    children?: React.ReactNode;
    className?: string;
    rowSpan?: number;
    columnSpan?: number;
    rowStart?: number;
    columnStart?: number;
    id?: string;
    padding?: number;
    gap?: number;
    noCap?: boolean;
    paper?: boolean;
    elevation?: 0 | 1 | 2 | 3 | 4 | 6 | 8 | 12 | 16 | 24;
  },
  ref: React.Ref<HTMLDivElement>,
) {
  if (noCap) {
    padding = 0;
    gap = 0;
  }

  return (
    <Cell
      className={classNames(className, "z-10", "item")}
      id={id}
      rowSpan={rowSpan}
      columnSpan={columnSpan}
      rowStart={rowStart}
      columnStart={columnStart}
      ref={ref}
    >
      <ConditionalWrapper
        condition={paper}
        wrapper={(children) => (
          <Paper
            elevation={elevation ?? 4}
            className={classNames("item-paper h-full w-full")}
            sx={{ p: padding }}
            square
          >
            {children}
          </Paper>
        )}
      >
        <Stack
          direction="column"
          flexWrap="nowrap"
          className="item-content h-full w-full"
          gap={!!label ? gap : 0}
        >
          {label && (
            <Typography variant="h2" className="item-label w-full flex-none">
              {label}
            </Typography>
          )}
          <Stack
            gap={gap}
            direction="column"
            flexWrap="nowrap"
            className={classNames(
              "item-content-inner h-full w-full flex-auto",
              !noCap && "overflow-y-auto",
            )}
          >
            {children}
          </Stack>
        </Stack>
      </ConditionalWrapper>
    </Cell>
  );
});
