import { useRecoilState } from "recoil";
import { toastMessageState } from "../state";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect, useState } from "react";

export default function Toast() {
  const [toastMessage, setToastMessage] = useRecoilState(toastMessageState);
  const [open, setOpen] = useState(false);
  useEffect(() => setOpen(!!toastMessage), [toastMessage]);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (["clickaway", "timeout"].includes(`${reason}`)) return;
    setOpen(false);
    setToastMessage("");
  };

  if (!toastMessage) return <></>;

  const action = (
    <IconButton size="small" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <Alert severity="warning" variant="standard" action={action}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
}
