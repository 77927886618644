import { atom, selector } from "recoil";
import { getStats, getUser, isApiError } from "../helpers/api";
import { keepInView, selectedClipIdsState } from "./clips";
import { User } from "../types/User";
import { defaultUser } from "./defaultUser";

export const toastMessageState = atom<string>({
  key: "toastMessage",
  default: "",
});

export const statsState = atom({
  key: "stats",
  default: selector({
    key: "statsQuery",
    get: async () => {
      const response = await getStats();
      if (isApiError(response)) throw Error("API is down");
      return response;
    },
  }),
});

export const currentPageState = atom({
  key: "currentPage",
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set currentPage to `, newValue);
      });
    },
  ],
});

const __gss = atom({
  key: "globalSelectionSignalInternalCounter",
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set __gss to `, newValue);
      });
    },
  ],
});

export const globalSelectionSignal = selector({
  key: "selectionSignal",
  get: ({ get }) => get(__gss),
  set: ({ set, reset }) => {
    console.warn(`global selection signal fired`);
    set(__gss, (old) => old + 1);
    reset(selectedClipIdsState);
    reset(keepInView);
  },
});

export const queryLoadingState = atom<boolean>({
  key: "queryLoading",
  default: false,
});

const __gqs = atom({
  key: "globalQuerySignalInternalCounter",
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.debug(`set __gqs to `, newValue);
      });
    },
  ],
});

export const globalQuerySignal = selector({
  key: "querySignal",
  get: ({ get }) => get(__gqs) + get(currentPageState) * 1000,
  set: ({ set }, _) => {
    console.warn(`global query signal fired`);
    set(__gqs, (old) => old + 1);
  },
});

export const userState = atom<User>({
  key: "user",
  default: selector({
    key: "user/query",
    get: async () =>
      await getUser().then((response) =>
        isApiError(response) ? defaultUser : { ...defaultUser, ...response },
      ),
  }),
});

export const isLoggedInState = selector<boolean>({
  key: "isLoggedIn",
  get: ({ get }) => get(userState)._id != defaultUser._id,
});

export const isAdminState = selector<boolean>({
  key: "isAdmin",
  get: ({ get }) => !!get(userState)?.isAdmin,
});

export const clipsPerPageState = selector({
  key: "clipsPerPage",
  get: ({ get }) => {
    const user = get(userState);
    return user.clipsPerPage;
  },
});
