import { Link } from "react-router-dom";
import { styled } from "styled-components";

import Stack from "@mui/material/Stack";

import { Cell, Griddy } from "./Griddy";
import { useRandomClips } from "../../hooks/useAPI";
import { ClipUrlProps, thumbnailUrl } from "../../config";
import { memo, useRef } from "react";
import useOnScreen from "../../hooks/useOnScreen";

const CellImage = styled.img`
  opacity: 0;
  &:not(.loaded) {
    opacity: 0 !important;
  }

  &.loaded {
    opacity: 0.08;
  }

  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  transition:
    opacity 3s ease-in-out,
    transform 0.2s ease-in-out,
    max-width 0.3s ease-out,
    left 0.3s ease-out,
    right 0.3s ease-out;

  &.loaded:hover {
    opacity: 1;
    z-index: 20;

    left: calc((calc((21 / 9) * 100%) - 100%) / -2);
    right: calc((calc((21 / 9) * 100%) - 100%) / -2);
    max-width: calc((21 / 9) * 100%);

    transform: scale(1.1);
    transition:
      opacity 0.2s ease-out,
      transform 0.1s ease-in-out 0.43s,
      max-width 0.1s ease-in-out 0.43s,
      left 0.1s ease-in-out 0.43s,
      right 0.1s ease-in-out 0.43s;
  }
`;

function loadHandler(event: React.SyntheticEvent<HTMLImageElement>) {
  event.currentTarget.classList.add("loaded");
}

const HomeContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  & em {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SmallImage = memo(function ({
  _id,
  anime,
  episode,
}: Partial<ClipUrlProps>) {
  if (!_id || !anime || episode == null) return <></>;
  return (
    <div className="relative h-1/2 w-1/2 overflow-visible">
      <Link to={`/clip/${_id}`} className="">
        <CellImage
          src={thumbnailUrl({ anime, episode, _id })}
          loading="lazy"
          onLoad={loadHandler}
          className="h-full object-cover"
        />
      </Link>
    </div>
  );
});

function Shy({ onVisible }: { onVisible: () => void }) {
  const ref = useRef<HTMLDivElement>(null);
  useOnScreen(ref)(onVisible);
  return (
    <div
      ref={ref}
      className="z-50 flex h-full w-full items-center justify-center overflow-clip"
    ></div>
  );
}

export function ThumbnailGrid({
  className,
  id,
}: {
  className?: string;
  id?: string;
}) {
  const perCell = 4;
  // magic number, 25 cells approximately fills a 16:9 screen at 1440p at gridSize 20
  const howMany = 25 * perCell;
  const howManyMore = 2 * perCell;
  const [clips, more] = useRandomClips(howMany);
  const cells = new Array(clips.length / perCell).fill(0);

  return (
    <Griddy className={className} id={id}>
      {cells.map((_, i) => (
        <Cell key={i}>
          <Stack
            direction="row"
            flexWrap="wrap"
            className="h-full w-full overflow-visible"
          >
            {Array(perCell)
              .fill(0)
              .map((_, j) => {
                const clip = clips[i * 4 + j];
                return <SmallImage key={clip?._id || j} {...clip} />;
              })}
          </Stack>
        </Cell>
      ))}
      <Cell>
        <Shy key={cells.length} onVisible={() => more(howManyMore)} />
      </Cell>
    </Griddy>
  );
}

export function ThumbnailGriddyPage({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <HomeContainer id="thumbnail-griddy-page-wrapper" className="">
      <Griddy className="relative min-w-full" id="content-griddy">
        <ThumbnailGrid
          id="thumbnail-grid"
          className="absolute top-0 left-0 h-full min-h-screen w-full overflow-hidden"
        />
        {children}
      </Griddy>
    </HomeContainer>
  );
}
