import { Anime } from "./types/Anime";
import { Clip } from "./types/Clip";

const API = import.meta.env.VITE_API as string;
const FILES = import.meta.env.VITE_FILES as string;

export const api = API;
export const files = FILES;

export type ClipUrlProps = Required<Pick<Clip, "anime" | "episode" | "_id">>;

export function thumbnailUrl(clip: ClipUrlProps) {
  const { anime, episode, _id } = clip;
  return `${FILES}/${anime}/${episode}/thumbnails/${_id}.jpg`;
}
export function videoUrl(clip: ClipUrlProps, preview: boolean = false) {
  const { anime, episode, _id = "" } = clip || {};
  if (!anime || episode == null || !_id) return null;
  const directory = preview ? `previews` : `clips`;
  return `${FILES}/${anime}/${episode}/${directory}/${_id}.mp4`;
}
export function coverUrl(anime: Anime) {
  if (!anime.cover) return ``;
  return `${FILES}/covers/${anime.cover}`;
}
export function apiUrl(...pages: string[]) {
  return `${API}/${pages.join("/")}`;
}
export function adminUrl(endpoint = "") {
  return apiUrl(`${endpoint}`);
}

const config = {
  api,
  files,
  thumbnailUrl,
  videoUrl,
  apiUrl,
};

export default config;
