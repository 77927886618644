import { User } from "../types/User";

const defaultLimit = 28;
const defaultSize = 25;

export const defaultUser: User = {
  _id: `defaultUser`,
  name: `Guest`,
  clipsPerPage: defaultLimit,
  autoplay: false,
  browseAutoplay: false,
  clipSize: defaultSize,
  selectionDismissal: false,
  showThumbnail: true,
  showTagTooltip: true,
  loadClipsInBrowse: true,
};
